import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography} from '@mui/material';

import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import Badge from "@mui/material/Badge";
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ListIcon from '@mui/icons-material/List';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isValidEmail, isValidUrl } from "../utils/helper";

const allCpAttributes = ["Company Size", "Geography", "Industry", "Vertical", "Team Size", "Buying Signals"]
const allCampaingDurations = ["1 Week", "2 Weeks", "3 Weeks", "4 Weeks"]

var attributeDict = {
  "Company Size": ["company_size"],
  "Geography": ["geographies"],
  "Industry": ["industries"],
  "Vertical":  ["verticals"],
  "Team Size": ["team_size"],
  "Buying Signals": ["buying_signals_tech", "buying_signals_non_tech"]
}

const preProcess = (arr) => {
  if(!arr) return arr
  var ans = []
  for(var i=0;i<arr.length;i+=1){
    ans = ans.concat(attributeDict[arr[i]])
  }
  return ans;
}

const showColumn = (leadList) => {
  var showEmail = true
  var showLinkedIn = true
  var showTwitter = true
  var showPhone = true
  var condensed = ""

  if(!("Email" in leadList["data"])) showEmail = false
  else{
    condensed = leadList["data"]["Email"].reduce((total, x) => total + "x", "")
    if(condensed === "") showEmail = false
  }

  if(!("LinkedIn" in leadList["data"])) showLinkedIn = false
  else{
    condensed = leadList["data"]["LinkedIn"].reduce((total, x) => total + "x", "")
    if(condensed === "") showLinkedIn = false
  }

  if(!("Twitter" in leadList["data"])) showTwitter = false
  else{
    condensed = leadList["data"]["Twitter"].reduce((total, x) => total + "x", "")
    if(condensed === "") showTwitter = false
  }

  if(!("Phone" in leadList["data"])) showPhone = false
  else{
    condensed = leadList["data"]["Phone"].reduce((total, x) => total + "x", "")
    if(condensed === "") showPhone = false
  }

  return {
    email: showEmail,
    linkedin: showLinkedIn,
    twitter: showTwitter,
    phone: showPhone,
  }
}

const emptyCampaign = {
  name: "",
  cp: null,
  cp_attributes: [],
  duration: "",
  status: null, //Active/Paused/Completed
  leads: [],
  filtered_leads: []
}

export default function AddCampaign(props) {
  var data

  if(props.subMode === "edit_campaign"){
    for(var i=0;i<props.content.length;i+=1){
      if(props.content[i]["_id"]["$oid"] === props.match.params.c_id){
        data = props.content[i]
        break
      }
    }
  } else if(props.subMode === "add_campaign") data = emptyCampaign

  const [step, setStep] = useState(props.subMode === "add_campaign"?1:2)
  const [campaignName, setCampaignName] = useState(data["name"])
  const [cp, setCp] = useState(data["cp"])
  const [cpAttributes, setCpAttributes] = useState(data["cp_attributes"])
  const [campaignDuration, setCampaignDuration] = useState(data["duration"])
  const [activity, setActivity] = useState(data["activity"])
  const [filteredLeadLists, setFilteredLeadLists] = useState([]) // if lead is selected for campaign
  const [isActiveLeads, setIsActiveLeads] = useState(null) // if selected criteria make leads eligible to be selected
  const [isSelectedList, setIsSelectedList] = useState(null) // stores if the checkbox to select full select is true
  const [hasProduct, setHasProduct] = useState("")

  const [campaignNameError, setCampaignNameError] = useState(false)
  const [cpError, setCpError] = useState(false)
  const [cpAttributesError, setCpAttributesError] = useState(false)
  const [campaignDurationError, setCampaignDurationError] = useState(false)
  const [leadCountError, setLeadCountError] = useState(false)
  const [hasProductError, setHasProductError] = useState(false)

  //filters
  const [cpOnlyLeads, setCpOnlyLeads] = useState(props.subMode === "add_campaign")
  const [useUnusedLeads, setUseUnusedLeads] = useState(props.subMode == "add_campaign")
  const [editFilterPopup, setEditFilterPopup]= useState(0) // changes to a positive integer depending on filter number
  
  const [leadLists, setLeadLists] = useState([])
  const [allCp, setAllCp] = useState([])
  const account_id = useSelector((state) => state.account.value)

  const applyFilter = (filteredLeadLists, leadLists, cpOnlyLeads) => {
    var fl = JSON.parse(JSON.stringify(filteredLeadLists))
    var isActive = Array(fl.length).fill("").map((x,i) => Array(fl[i].length).fill(false))

    for(var i=0;i<leadLists.length;i+=1){
      for(var j=0;j<leadLists[i]["data"]["Email"].length;j+=1){
        var state = true

        if(state){
          // check condition for cpOnlyLeads
          if(leadLists[i]["metadata"]["cp"][j] === ""){
            state = !cpOnlyLeads
          }else if(leadLists[i]["metadata"]["cp"][j]["name"] !== cp["name"]) 
            state = false
        }
        
        if(state){
          //further process if lead is not eliminated yet
          //check if lead is already used before in campaign
        }

        // if not active, remove from filteredlist
        fl[i][j] = fl[i][j] && state
        isActive[i][j] = state
      }
    }

    return [fl, isActive]
  }

  useEffect(() => {
    if(props.subMode === "edit_campaign"){
      setLeadLists(data["leads"])
      var output = applyFilter(data["filtered_leads"], data["leads"], cpOnlyLeads)
    
      setIsActiveLeads(output[1])
      setFilteredLeadLists(output[0])
      setIsSelectedList(Array(output[0].length).fill(false))

      props.callback("heading", data["name"])
      props.callback("description", "Edit campaign and Save Changes")
    }else{
    axios.get(BACKEND_ADDRESS + '/lead_list/all_lead_lists/', {params: {account_id: account_id}})
    .then((response) => {
      var ll = response.data["lead_lists"]
      //show only contact columns that have non blank value
      for(var i=0;i<ll.length;i+=1){
        if(ll[i]["data"]["Email"].length === 0) ll[i]["filteredFields"] = ll[i]["fields"]
        else{
          var colData = showColumn(ll[i])
          var filteredFields = []

          for(var j=0;j<ll[i]["fields"].length;j+=1){
            if(ll[i]["fields"][j] === "Email" && !colData["email"]) continue
            if(ll[i]["fields"][j] === "LinkedIn" && !colData["linkedin"]) continue
            if(ll[i]["fields"][j] === "Twitter" && !colData["twitter"]) continue
            if(ll[i]["fields"][j] === "Phone" && !colData["phone"]) continue

            filteredFields.push(ll[i]["fields"][j])
          }

          ll[i]["filteredFields"] = filteredFields
        }
      }

      setLeadLists(ll)
      setFilteredLeadLists(ll.map((x, id1) => x["data"]["Email"].map((y, id2) => false)))
    })

    axios.get(BACKEND_ADDRESS + '/cp/all_cps/', {params: {account_id: account_id}})
    .then((response) => {
      var result = response.data["customer_profiles"]
      result = result.map((x, ind) => {return {ind: ind, ...x}})
      setAllCp(result)
    })
    }
  }, [])

  useEffect(() => {
    if(step !== 2 || filteredLeadLists.length === 0) return;
    var output = applyFilter(filteredLeadLists, leadLists, cpOnlyLeads)
    
    setIsActiveLeads(output[1])
    setFilteredLeadLists(output[0])
    setIsSelectedList(Array(output[0].length).fill(false))
  }, [step, useUnusedLeads, cpOnlyLeads])

  const maxSteps = 2
  let history = useHistory();

  var totalLeads = filteredLeadLists.reduce((total, x) => total + x.reduce((tot, y) => tot + (y?1:0), 0), 0)

  const validateEntry = () => {
    var flag = true

    if(step === 1){
      if(campaignName === ""){
        flag = false
        setCampaignNameError(true)
      }else{
        setCampaignNameError(false)
      }

      if(cp === null){
        flag = false
        setCpError(true)
      }else{
        setCpError(false)
      }

      if(cpAttributes.length > 2 || cpAttributes.length < 1){
        flag = false
        setCpAttributesError(true)
      }else{
        setCpAttributesError(false)
      }

      if(campaignDuration === ""){
        flag = false
        setCampaignDurationError(true)
      }else{
        setCampaignDurationError(false)
      }

      if(hasProduct === ""){
        flag = false
        setHasProductError(true)
      }else{
        setHasProductError(false)
      }

      if(flag){
        //setFilteredLeadLists() // to be received from backend
      }
    }else if(step === 2){
      if(totalLeads < 10 || totalLeads > 100){
        setLeadCountError(true)
        flag = false
      }
      else setLeadCountError(false)
    }

    if(flag){
      if(step === maxSteps){
        const blankValues = filteredLeadLists.map((x, ind1) => x.map((y, ind2) => null))
        const blankValuesStr = filteredLeadLists.map((x, ind1) => x.map((y, ind2) => ""))

        if(props.subMode === "add_campaign"){
        //add lead status
        const leadStatus = leadLists.map(x => x["data"]["Email"].map(y => [{
          type: "New",
          status: "completed" // stage is active/completed
        }]))

        const newCampaignData = {
          account_id: account_id,
          name: campaignName,
          cp: cp,
          cp_attributes: preProcess(cpAttributes),
          duration: campaignDuration,
          status: "Active", //Active/Paused/Completed
          leads: leadLists,
          filtered_leads: filteredLeadLists,
          has_product: (hasProduct==="Yes"),
          active_stage: 1,
          copy_source: -2, //-1 is aspiro template and -2 means no option is chosen in the start
          copy_data: [],
          custom_copy: [],
          edit_copy: false,

          //other empty intializations
          is_active: true,
          lead_notes: blankValuesStr,
          lead_status: leadStatus,
          problem_validation_responses: cp["problems"].reduce((acc, element) => {acc[element] = blankValues; return acc}, {}),
          buying_intent_responses: blankValues,
          verdicts: blankValues
        }
        axios.post(BACKEND_ADDRESS + '/campaign/', newCampaignData)
          .then(response => {ToastComponent("info", "Campaign Created!"); history.push('/campaigns')})
          .catch(error => ToastComponent("error", "Failed to create campaign!"))

        }else{
          //edit campaign
          var newCampaignData = data
          newCampaignData["filtered_leads"] = filteredLeadLists

          newCampaignData["id"] = data["_id"]["$oid"]
          axios.put(BACKEND_ADDRESS + '/campaign/', newCampaignData)
          .then(response => {ToastComponent("info", "Campaign Updated!"); history.push("/campaigns/" + props.match.params.c_id + "/edit")})
          .catch(error => ToastComponent("error", "Failed to update campaign!"))
        }
      }
      else setStep(step + 1)
    }
  }

  const updateFilteredLead = (listNo, rowIndex) => {
    var fl = JSON.parse(JSON.stringify(filteredLeadLists))
    fl[listNo][rowIndex] = !fl[listNo][rowIndex]
    setFilteredLeadLists(fl)
  }

  const selectAllLeads = () => {
    var fl = JSON.parse(JSON.stringify(filteredLeadLists))
    for(var i=0;i<fl.length;i+=1){
      for(var j=0;j<fl[i].length;j+=1){
        if(isActiveLeads[i][j]) fl[i][j] = true
      }
    }
    setFilteredLeadLists(fl)
  }

  const deSelectAllLeads = () => {
    var fl = JSON.parse(JSON.stringify(filteredLeadLists))
    for(var i=0;i<fl.length;i+=1){
      for(var j=0;j<fl[i].length;j+=1){
        fl[i][j] = false
      }
    }
    setFilteredLeadLists(fl)
  }

  const updateFilteredLeadList = (e, listNo) => {
    var fl = JSON.parse(JSON.stringify(filteredLeadLists))
    var sl = JSON.parse(JSON.stringify(isSelectedList))
    
    //fl[listNo] = Array(fl[listNo].length).fill(e.target.checked)
    for(var i=0;i<fl[listNo].length;i+=1){
      if(isActiveLeads[listNo][i]) fl[listNo][i] = e.target.checked
    }
    setFilteredLeadLists(fl)

    sl[listNo] = e.target.checked
    setIsSelectedList(sl)
  }

  const handleFilterChange = () => {
    if(editFilterPopup === 1) setUseUnusedLeads(!useUnusedLeads)
    else if(editFilterPopup === 2) setCpOnlyLeads(!cpOnlyLeads)

    setEditFilterPopup(0)
  }
  
  return (
    <>
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} style={{display: "flex", flexDirection: "column"}}>
          <Paper style={{borderRadius: "8px", padding: "24px", minHeight: "500px",
          justifyContent: 'space-between', maxWidth: "90vw", overflowX: "auto"}}>
            <Grid container direction="column" spacing={4} alignItems="flex-start">
              {step === 1?
                <>
                  <Grid item>
                    <Typography>1. Campaign Name</Typography>
                    <br />
                    <TextField placeholder="US vs India Validation" onChange={(e) => setCampaignName(e.target.value)}
                    error={campaignNameError} helperText={campaignNameError?"Campaign Name can't be blank":""} defaultValue={campaignName}
                    variant="standard" required InputLabelProps={{sx: {color: "#395af6"}}} sx={{width: "300px"}} />
                  </Grid>

                  <Grid item>
                    <Typography>2. Select Customer Profile</Typography>
                    <br />
                    <FormControl variant="standard" sx={{width: "300px"}}>
                      <Select variant="standard" value={cp?cp["ind"]:""} onChange={e => {deSelectAllLeads(); setCp(allCp[e.target.value])}}>
                        {allCp.map((x, ind) => <MenuItem key={ind} value={ind}>{x["name"]}</MenuItem>)}
                      </Select>
                      <FormHelperText style={{color:"red"}}>{cpError?"Please select customer profile":""}</FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <Typography>3. Which attributes do you want to validate in this campaign? (Max 2)</Typography>
                    <br />
                    <Stack direction="column" spacing={1} sx={{width: "850px"}}>
                      <ToggleButtonGroup color="primary" value={cpAttributes} onChange={(_, v) => {setCpAttributes(v)}} aria-label="text formatting">
                        {allCpAttributes.map((x, ind) =>
                          <ToggleButton key={ind} aria-label={x} value={x} sx={{textTransform: 'none'}}>
                            <Stack direction="column">
                              <Typography><b>{x}</b></Typography>
                              <Typography variant="subtitle2">Last Validated: N/A</Typography>
                            </Stack>
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item><Typography color="#d2222d" display="inline">High</Typography><Typography display="inline"> GTM Senstivity</Typography></Grid>
                        <Grid item><ArrowRightAltIcon/></Grid>
                        <Grid item><Typography color="#ffbf00" display="inline">Medium</Typography><Typography display="inline"> GTM Senstivity</Typography></Grid>
                        <Grid item><ArrowRightAltIcon/></Grid>
                        <Grid item><Typography color="#2ecf03" display="inline">Low</Typography><Typography display="inline"> GTM Senstivity</Typography></Grid>
                      </Grid>
                      {cpAttributesError?<Typography variant="subtitle2" color="red" sx={{marginTop: 1}}>Select at least 1 and not more than 2 attributes</Typography>:<></>}
                    </Stack>
                  </Grid>

                  <Grid item>
                    <Typography>4. Do you have a product?</Typography>
                    <br />
                    <FormControl variant="standard" sx={{width: "200px"}}>
                      <Select variant="standard" value={hasProduct} onChange={(e) => setHasProduct(e.target.value)}>
                        {["Yes", "No"].map((x, ind) =>
                          <MenuItem key={ind} value={ind}>{x}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {hasProductError?<Typography variant="subtitle2" color="red" sx={{marginTop: 1}}>Tell us if you have a product</Typography>:<></>}
                  </Grid>

                  <Grid item>
                    <Typography>5. Select Campaign Duration</Typography>
                    <br />
                    <Stack direction="column" spacing={1} sx={{width: "850px"}}>
                      <ToggleButtonGroup exclusive color="primary" value={campaignDuration} onChange={(_, v) => setCampaignDuration(v)}>
                        {allCampaingDurations.map((x, ind) =>
                          x === "2 Weeks"?
                          <Badge badgeContent="Best" color="primary" sx={{'& .MuiBadge-badge': {mr:5.5}}}>
                            <ToggleButton key={ind} aria-label={x} value={parseInt(x.split(" ")[0])} sx={{textTransform: 'none'}}>
                              <Stack direction="column"><Typography>{x}</Typography></Stack>
                            </ToggleButton>
                          </Badge>
                          :
                          <ToggleButton key={ind} aria-label={x} value={parseInt(x.split(" ")[0])} sx={{textTransform: 'none'}}>
                            <Stack direction="column"><Typography>{x}</Typography></Stack>
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                      {campaignDurationError?<Typography variant="subtitle2" color="red" sx={{marginTop: 1}}>Select a suitable campaign duration</Typography>:<></>}
                    </Stack>
                  </Grid>
                </>
              : step === 2?
                <>
                  <Grid item>
                    {props.subMode === "add_campaign"?
                    <><Typography>6. Select leads for this campaign</Typography><br /></>
                    :<></>
                    }
                    {leadLists.length && isSelectedList !== null?
                    <>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Stack direction="column" spacing={1}>
                        <Typography>Total Selected Leads: {totalLeads}</Typography>
                        <Button variant="outlined" onClick={selectAllLeads}>Select All Leads</Button>
                      </Stack>
                      <Stack direction="column">
                        <Typography>Filters</Typography>
                        <Stack direction="row" alignItems="center">
                          <Checkbox size="small" checked={useUnusedLeads} onClick={() => setEditFilterPopup(1)} />
                          <Typography>Only use fresh leads (not used in other campaigns)</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <Checkbox size="small" checked={cpOnlyLeads} onClick={() => setEditFilterPopup(2)} />
                          <Typography>Only use leads from <b>{cp["name"]}</b></Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    
                    {leadLists.map((leadList, listNo) =>
                      <React.Fragment key={listNo}>
                        {leadList["fields"].length > 0?
                          <>
                          <Stack direction="row" alignItems="center">
                            <Checkbox size="small" key={listNo} checked={isSelectedList[listNo]} onChange={(e) => updateFilteredLeadList(e, listNo)} />
                            <Typography>{leadList["name"]}</Typography>
                          </Stack>
                          <TableContainer component={Paper} sx={{maxWidth: "1050px", overflowX: "auto"}}>
                            <Table size="small">
                              <TableHead style={{backgroundColor: "#f5f5f5"}}>
                                <TableRow>
                                  <TableCell sx={{maxWidth:"40px"}}/>
                                  {leadList["filteredFields"].map((x, ind)=> {
                                    return (
                                      <TableCell key={ind}>
                                        <Typography>{x}</Typography>
                                      </TableCell>
                                    )
                                  })}
                                  <TableCell sx={{minWidth: "250"}}><Typography>Customer Profile Tags</Typography></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Array.from({length: leadList["data"]["Email"].length}).map((_, rowIndex) =>{
                                  if(isActiveLeads !== null && isActiveLeads[listNo][rowIndex]){
                                    return (
                                      <TableRow key={rowIndex} sx={{'&:last-child td, &:last-child th': {border: 0}}} style={{backgroundColor: filteredLeadLists[listNo][rowIndex]?'rgba(57, 90, 246, 0.1)':'white'}}>
                                        <TableCell sx={{p:"6", maxWidth: "40"}}>
                                          <Checkbox size="small" key={rowIndex} checked={filteredLeadLists[listNo][rowIndex]} onChange={(e) => updateFilteredLead(listNo, rowIndex)} />
                                        </TableCell>
                                        {leadList["filteredFields"].map((field, colIndex) => 
                                          <TableCell key={field} component="th" scope="row">
                                            {isValidUrl(leadList["data"][field][rowIndex])?
                                              <a target="_blank" href={leadList["data"][field][rowIndex]}>
                                                {leadList["data"][field][rowIndex]}
                                              </a>
                                            :isValidEmail(leadList["data"][field][rowIndex])?
                                              <a href={"mailto:"+leadList["data"][field][rowIndex]}>
                                                <Chip avatar={<Avatar>{leadList["data"][field][rowIndex][0]}</Avatar>}
                                                  variant="outlined" label={leadList["data"][field][rowIndex]} />
                                              </a>
                                            :leadList["data"][field][rowIndex]
                                            }
                                          </TableCell>
                                        )}
                                        <TableCell>
                                        {leadList["metadata"]["cp"][rowIndex]["name"] !== ""?<>{"Customer Profile: "}<Chip size="small" label={leadList["metadata"]["cp"][rowIndex]["name"]} sx={{color: "primary.main"}}/><br /></>:<></>}
                                        {leadList["metadata"]["persona"][rowIndex] !== "" && leadList["metadata"]["persona"][rowIndex]["designation"] !== ""?<>{"Persona: "}<Chip size="small" label={leadList["metadata"]["persona"][rowIndex]["designation"] + " at " + leadList["metadata"]["persona"][rowIndex]["vertical"]} sx={{color: "primary.main"}}/><br /></>:<></>}
                                        {leadList["metadata"]["industry"][rowIndex] !== ""?<>{"Industry: "}<Chip size="small" label={leadList["metadata"]["industry"][rowIndex]} sx={{color: "primary.main"}}/>&nbsp;</>:<></>}
                                        {leadList["metadata"]["vertical"][rowIndex] !== ""?<>{"Vertical: "}<Chip size="small" label={leadList["metadata"]["vertical"][rowIndex]} sx={{color: "primary.main"}}/>&nbsp;</>:<></>}
                                        {leadList["metadata"]["geography"][rowIndex] !== ""?<>{"Geography: "}<Chip size="small" label={leadList["metadata"]["geography"][rowIndex]} sx={{color: "primary.main"}}/><br /></>:<></>}
                                        {leadList["metadata"]["company_size"][rowIndex] !== ""?<>{"Company Size: "}<Chip size="small" label={leadList["metadata"]["company_size"][rowIndex]} sx={{color: "primary.main"}}/>&nbsp;</>:<></>}
                                        {leadList["metadata"]["team_size"][rowIndex] !== ""?<>{"Team Size: "}<Chip size="small" label={leadList["metadata"]["team_size"][rowIndex]} sx={{color: "primary.main"}}/><br /></>:<></>}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  }
                                  else{
                                    return (<React.Fragment key={rowIndex}></React.Fragment>)
                                  }
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <br />
                          </>
                          :<></>
                        }
                      </React.Fragment>
                    )}
                    <Typography color="red" variant="subtitle2">{leadCountError?"Select between 10-100 leads to start a campaign":""}</Typography>
                    </>:
                    <>
                    <Typography>You haven't added any leads. Add leads before you start a campaign</Typography>&nbsp;
                    <Button variant="contained" onClick={() => history.push('/leads/')}>Add New Leads</Button>
                    </>
                    }
                  </Grid>
                </>
              :<></>
              }
            </Grid>
            
            {props.subMode === "add_campaign"?
            <Grid item container direction="row" justifyContent="space-between" spacing={1}>
              <Grid item xs={12}><Divider style={{marginTop: "12px"}}/></Grid>
              <Grid item>
                {step !== 1?
                  <Button variant="outlined" sx={{textTransform: 'none'}} onClick={() => {if(step > 1) setStep(step-1)}}>Back</Button>
                :<></>
                }
              </Grid>
              <Grid item>
                <Typography variant="h6">Step {step}/{maxSteps}</Typography>
              </Grid>
              <Grid item>
                {step === maxSteps?
                  <Button variant="contained" sx={{textTransform: 'none'}} onClick={validateEntry}>Start Campaign</Button>
                  :
                  <Button variant="contained" sx={{textTransform: 'none'}} onClick={validateEntry}>Next</Button>
                }
              </Grid>
            </Grid>
            :
              <></>
            }
          </Paper>
        
      </Grid>
      {props.subMode === "edit_campaign"?
      <Paper square elevation={0} style={{minWidth: "100%", maxWidth: "100%", position: "fixed", bottom: "0px", zIndex: 5, padding: "8 16"}}>
        <center>
          <Button variant="contained" sx={{textTransform: 'none'}} onClick={validateEntry}>{"Save Changes and continue Campaign"}</Button>
        </center>
      </Paper>
      :<></>}
    </Grid>
    <Dialog open={editFilterPopup > 0}>
      <DialogTitle >
        Warning!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Changing this filter may unselect some selected leads.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setEditFilterPopup(0)}>Cancel</Button>
        <Button variant="contained" onClick={handleFilterChange} autoFocus>Go ahead</Button>
      </DialogActions>
    </Dialog>
    </>
  )
}
