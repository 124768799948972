import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS } from "./../utils/constants"
import TipBox from "./../utils/TipBox"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

import ToastComponent from "./../utils/ToastComponent";

// values taken from LinkedIn sales navigator- https://www.awesomescreenshot.com/video/28493273?key=a673d1a3fa5a359f45360f58626766ed
const verticalOptions = ["Accounting", "Administrative", "Arts & Design", "Business Development",
"Community and Social Service", "Consulting", "Education", "Engineering", "Entrepreneurship",
"Finance", "Healthcare Services", "Human Resources", "Information Technology", "Legal", "Marketing",
"Media and Communication", "Military and Protective Services", "Operations", "Product Management",
"Program & Project Management", "Purchasing", "Quality Assurance", "Real Estate", "Research",
"Sales", "Customer Success and Support"]

const companySizesOptions = ["Self-Employed", "1-10", "11-50", "51-200", "201-500",
"501-1000", "1001-5000", "5001-10000", "10001+"]

const geographiesOptions = ["Americas", "EU", "APAC", "MEA"]
const geographiesOptionsDetailed = ["Oceania", "EMEA", "DACH", "North America", "Benelux", "APJ", "APAC", "Asia", "Nordics", "MENA", "Europe", "Africa", "South America"]

const teamSizesOptions = ["Range of 10", "Range of 50", "Range of 100"]

export default function AddCustomerProfile(props){
  const data = props.data
  const account_id = useSelector((state) => state.account.value)

  const [step, setStep] = useState(1)
  const [cpName, setCpName] = useState(data["name"])
  const [description, setDescription] = useState(data["description"])
  const [cpStage, setCpStage] = useState(data["stage"])
  const [industries, setIndustries] = useState(data["industries"])
  const [verticals, setVerticals] = useState(data["verticals"])
  const [companySizes, setCompanySizes] = useState(data["company_size"])
  const [teamSizes, setTeamSizes] = useState(data["team_size"])
  const [geographies, setGeographies] = useState(data["geographies"])
  const [buyingSignalsTech, setBuyingSignalTech] = useState(data["buying_signals_tech"])
  const [buyingSignalsNonTech, setBuyingSignalNonTech] = useState(data["buying_signals_non_tech"])
  const [problems, setProblems] = useState(data["problems"])

  const [cpNameError, setCpNameError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [cpStageError, setCpStageError] = useState(false)
  const [industriesError, setIndustriesError] = useState(false)
  const [verticalsError, setVerticalsError] = useState(false)
  const [companySizesError, setCompanySizesError] = useState(false)
  const [teamSizesError, setTeamSizesError] = useState(false)
  const [geographiesError, setGeographiesError] = useState(false)
  const [problemsError, setProblemsError] = useState(false)

  const maxSteps = 3
  let history = useHistory();

  const updateProblems = (e, ind) => {
    var problemCopy = problems
    problemCopy[ind] = e.target.value
    setProblems(problemCopy)
  }

  const validateEntry = () => {
    var flag = true

    if(step === 1){
      if(cpName === ""){
        flag = false
        setCpNameError(true)
      }else{
        setCpNameError(false)
      }

      if(description === ""){
        flag = false
        setDescriptionError(true)
      }else{
        setDescriptionError(false)
      }

      // if(cpStage === null){
      //   flag = false
      //   setCpStageError(true)
      // }else{
      //   setCpStageError(false)
      // }

      var count = 0
      var probs = []
      for(var i=0;i<problems.length;i+=1){
        if(problems[i] !== ""){
          probs.push(problems[i])
        }
      }
      setProblems(probs)

      if(probs.length < 2){
        flag = false
        setProblemsError(true)
      }else{
        setProblemsError(false)
      }
    }else if(step === 2){
      if(industries.length < 1){
        flag = false
        setIndustriesError(true)
      }else{
        setIndustriesError(false)
      }

      if(verticals.length < 1){
        flag = false
        setVerticalsError(true)
      }else{
        setVerticalsError(false)
      }

      if(geographies.length < 1){
        flag = false
        setGeographiesError(true)
      }else{
        setGeographiesError(false)
      }
    }else if(step === 3){
      if(companySizes.length < 1){
        flag = false
        setCompanySizesError(true)
      }else{
        setCompanySizesError(false)
      }

      if(teamSizes.length < 1){
        flag = false
        setTeamSizesError(true)
      }else{
        setTeamSizesError(false)
      }
    }

    if(flag){
      if(step === maxSteps){
        var newCustomerProfile = {
          account_id: account_id,
          is_active: true,
          name: cpName,
          description: description,
          problems: problems,
          industries: industries,
          verticals: verticals,
          company_size: companySizes,
          team_size: teamSizes,
          geographies: geographies,
          stage: cpStage,
          personas: [],
          buying_signals_tech: buyingSignalsTech,
          buying_signals_non_tech: buyingSignalsNonTech
        }

        if(props.mode === "add"){
          axios.post(BACKEND_ADDRESS + '/cp/', newCustomerProfile)
          .then(response => {
            ToastComponent("info", "Customer Profile created!")
            history.push('/icp')
          }).catch(error => ToastComponent("error", "Can't create Customer Profile!"))
        }else if(props.mode === "edit"){
          newCustomerProfile["id"] = props.data["_id"]["$oid"]
          newCustomerProfile["personas"] = props.data["personas"].map(x => x["_id"]["$oid"]) //as we aren't updating personas here

          axios.put(BACKEND_ADDRESS + '/cp/', newCustomerProfile)
          .then(response => {
            ToastComponent("info", "Customer Profile updated!")
            history.push('/icp')
          }).catch(error => ToastComponent("error", "Can't update Customer Profile!"))
        }
      }
      else setStep(step + 1)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10} lg={8} container direction="column" spacing={2}>
        <Grid item>
          <Paper style={{borderRadius: "8px", padding: "24px", minHeight: "500px", maxWidth: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Grid container item direction="column" spacing={4}>
              {step === 1?
                <>
                  <Grid item>
                    <Typography>1. Customer Profile Name</Typography>
                    <br />
                    <TextField placeholder="Fortune 500 Companies" onChange={(e) => setCpName(e.target.value)}
                    error={cpNameError} helperText={cpNameError?"Customer Profile Name can't be blank":""} defaultValue={cpName}
                    variant="standard" required sx={{width: "100%"}} />
                  </Grid>

                  <Grid item>
                    <Typography>2. Customer Profile Description</Typography>
                    <br />
                    <TextField placeholder="Fortune 500 companies in technology space, who run product marketplaces"
                    onChange={(e) => setDescription(e.target.value)} error={descriptionError}
                    helperText={descriptionError?"Customer Profile Description can't be blank":""} defaultValue={description}
                    variant="standard" required sx={{width: "100%"}} multiline/>
                  </Grid>
                  <Grid item>
                    <Typography>3. Write down the Problems faced by this Customer, that you are solving for</Typography>
                    <br />
                    <TextField key={1} placeholder="Primary Problem" required onChange={(e) => updateProblems(e, 0)}
                    defaultValue={problems[0]}
                    variant="standard" sx={{width: "100%"}} multiline/>
                    <TextField key={2} placeholder="Primary/Secondary Problem" required onChange={(e) => updateProblems(e, 1)}
                    defaultValue={problems[1]}
                    variant="standard" sx={{width: "100%"}} multiline/>
                    <br />
                    <TextField key={3} placeholder="(Optional) Secondary Problem" onChange={(e) => updateProblems(e, 2)}
                    error={problemsError} helperText={problemsError?"Write down atleast 2  problems":""} defaultValue={problems[2]}
                    variant="standard" sx={{width: "100%"}} multiline/>
                    <br /><br />
                  </Grid>
                </>
              :step === 2?
                <>
                  <Grid item>
                    <TipBox tip={
                      <>
                        <Typography>When validating a Customer Profile, your goal is to filter out attribute values where you don't find market interest.
                        So for each attribute, add <b>all</b> relevant values that you will test.</Typography>
                      </>
                    } />
                  </Grid>
                  <Grid item sx={{mb: -1}}>
                    <Typography>Tell us about where do you search for these customers-</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>4. Industries (at least 1)</Typography>
                    <br />
                    <Autocomplete multiple freeSolo options={[]} value={industries} sx={{maxWidth: "100%"}}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))}
                      onChange={(event, newValue) => {setIndustries(newValue)}}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard"
                        placeholder="Press enter after typing to add" helperText={industriesError?"Add at least 1 Industry":""} error={industriesError}/>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>5. Verticals (at least 1)</Typography>
                    <br />
                    <Autocomplete multiple options={verticalOptions} value={verticals}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))}
                      onChange={(event, newValue) => {setVerticals(newValue)}}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" value=""
                        placeholder="Select from dropdown" helperText={verticalsError?"Add at least 1 Vertical":""} error={verticalsError}/>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>6. Geographies (at least 1)</Typography>
                    <br />
                    <Autocomplete multiple options={geographiesOptions} value={geographies}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))}
                      onChange={(event, newValue) => {setGeographies(newValue)}}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" value=""
                        placeholder="Select from dropdown" helperText={geographiesError?"Add at least 1 Geography":""}
                        error={geographiesError}/>
                      )}
                    />
                  </Grid>
                </>
              :step === 3?
                <>
                  <Grid item>
                    <Typography>7. Customer Company Size (at least 1 range)</Typography>
                    <br />
                    <Autocomplete multiple options={companySizesOptions} value={companySizes}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))}
                      onChange={(event, newValue) => {setCompanySizes(newValue)}}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" value=""
                        placeholder="Select from dropdown" helperText={companySizesError?"Add at least 1 Company Size":""}
                        error={companySizesError}/>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>8. Customer Team Size (at least 1 range)</Typography>
                    <br />
                    <Autocomplete multiple options={teamSizesOptions} value={teamSizes}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))}
                      onChange={(event, newValue) => {setTeamSizes(newValue)}}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" value=""
                        placeholder="Select from dropdown" helperText={teamSizesError?"Add at least 1 Team Size":""} error={teamSizesError}/>
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <TipBox tip={
                      <>
                        <Typography fontSize="15">
                        <b>Buying signals</b> are cues/indications from a potential customer that show their readiness to make a purchase.
                        Over here, we will be restricting to buying signals that can be found during prospecting a customer (before you reachout to them).<br /><br />
                        Examples-<br />
                        <b>1. Technical Buying Signals-</b> Customer using a cybersecurity SaaS, graphQL in tech stack, ⁠⁠using open sourced LLMs, ⁠having two dedicated MLOps hires etc.<br />
                        <b>2. Non Technical Buying Signals-</b> Customer downloading your gated website content (lead magnet), occasionally engaging with your LinkedIn/X content, free trial signup etc.<br />
                        </Typography>
                      </>
                    } />
                  </Grid>
                  <Grid item>
                    <Typography>9. Technical Buying Signals</Typography>
                    <br />
                    <Autocomplete multiple freeSolo options={[]} value={buyingSignalsTech}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))}
                      onChange={(event, newValue) => {setBuyingSignalTech(newValue)}}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" placeholder="Press enter after typing to add" />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>10. Non-Technical Buying Signals</Typography>
                    <br />
                    <Autocomplete multiple freeSolo options={[]} value={buyingSignalsNonTech}
                      renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))}
                      onChange={(event, newValue) => {setBuyingSignalNonTech(newValue)}}
                      renderInput={(params) => (
                        <TextField {...params} required variant="standard" placeholder="Press enter after typing to add" />
                      )}
                    />
                  </Grid>
                </>
              :<></>
              }
            </Grid>

            <Grid item container direction="row" justifyContent="space-between" spacing={1}>
              <Grid item xs={12}><Divider style={{marginTop: "12px"}}/></Grid>
              <Grid item>
                {step !== 1?
                  <Button variant="outlined" sx={{textTransform: 'none'}} onClick={() => {if(step > 1) setStep(step-1)}}>Back</Button>
                :<></>
                }
              </Grid>
              <Grid item>
                <Typography variant="h6">Step {step}/{maxSteps}</Typography>
              </Grid>
              <Grid item>
                {step === maxSteps?
                  <Button variant="contained" sx={{textTransform: 'none'}} onClick={validateEntry}>{props.mode === "add"?"Submit":"Save Changes"}</Button>
                  :
                  <Button variant="contained" sx={{textTransform: 'none'}} onClick={validateEntry}>Next</Button>
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}
